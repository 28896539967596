.navigation-bar {
    height: 90px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    z-index: 2;
    background-color: white;
}

.navigation-bar p {
    font-weight: 500;
    font-family: 'Prompt', sans-serif;
    text-transform: uppercase;
    color: black;
    font-size: 14px;
    margin:0;
    padding:0;
}

nav ul {
    list-style: none;
    display: flex;
    justify-content: center;
    width: 450px;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
}

nav ul li {
    margin: 20px;
    font-size: medium;
}

.dropdown-virag {
    position: absolute;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    background-color: #fff;
    border-radius: 6px;
    border-top: 2px solid #dbdbdb;
    width: 270px;
    animation: fadeIn 0.4s;
    box-shadow: 0 8px 8px hsl(0deg 0% 4% / 10%);
    z-index: 10;
}

.dropdown-virag ul {
    padding: 0;
}

/*
.dropdown-virag li::before {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: black;
    top: 40%;
    left: 2px;
    border-radius: 50%;
}
*/

.dropdown-virag p {
    margin: 0;
    padding: 0;
}

.dropdown-virag li {
    margin: 0;
    padding: 10px 0 10px 10px;
    font-weight: 300 !important;
    font-family: 'Prompt', sans-serif;
    text-transform: none !important;
    font-size: 14px;
    cursor: pointer;
    transition: 1s;
    position: relative;
}

.dropdown-virag li p {
    font-weight: 500 !important;
    text-transform: none !important;

}

.dropdown-virag li:hover {
    color: black;
}

.hamburguer-menu {
    height: 40px;
    width: 40px;
    z-index: 5;
    display: flex;
    flex-direction: column;
    gap: 7px;
    background-color: rgba(255, 188, 3, 1);
    padding: 5px;
    border-radius: 5px;
    top: 5px;
    left: 5px;
    cursor: pointer;
    position: relative;
}

.navigation-position-fixed {
    position: fixed;
}

.hamburguer-menu span {
    width: 100%;
    height: 5px;
    background-color: white;
    border-radius: 10% / 50%;
}

.hamburguer-menu-wrapper {
    width: 100%;
    background-color: rgba(255, 188, 3, 0.2);
    height: 52px;
    top: 0;
    z-index: 5;
    display: flex;
    justify-content: space-between;
}

.hamburguer-menu-wrapper a {
    width: 65px;
}

.hamburguer-menu-wrapper img {
    margin-top: 2px;
    margin-left: 18px;
    height: 48px;
}

.lateral-navigation {
    width: 100vw;
    height: 100vh;
    transition: 1s;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10;
    position: fixed;
    top: 0;
    animation-name: lateral-enter;
    animation-duration: 1s;
}

.close-lateral-bar-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.close-lateral-bar {
    width: 50px;
    height: 50px;
    display: flex;
    position: relative;
}

.close-lateral-bar span {
    width: 90%;
    height: 5px;
    background-color: white;
    position: absolute;
    left: 0;
    top: 50%;
}

.close-one {
    transform: rotate(45deg);
}

.close-two {
    transform: rotate(-45deg);
}

.hide-lateral-bar {
    width: 100vw;
    height: 100vh;
    transition: 1s;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10;
    position: fixed;
    top: 0;
    transform: translateX(-500px);
    animation-name: hide-bar;
    animation-duration: 1s;
}

.lateral-navigation-elements ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.lateral-navigation-elements li {
    color: white;
    font-weight: 500;
    font-family: 'Prompt', sans-serif;
    text-transform: uppercase;
    font-size: 14px;
    border-bottom: 0.1px solid white;
    padding-bottom: 10px;
    margin-bottom: 15px;
    width: 90%;
    transition: 1s;
}

.lateral-navigation-elements li:hover {
    color: #ffbc03;
}

.lateral-navigation-submenu {
    padding-top: 0px !important;
}

@keyframes lateral-enter {
    from {
        transform: translateX(-500px);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes hide-bar {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-500px);
    }
    
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }