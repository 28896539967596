/*
.home-header img {
    position: relative;
    width: 100vw;
    object-fit: cover;
    clip-path: polygon(0 39%, 100% 39%, 100% 83%, 0 83%);
    margin-top: -29.3%;
    margin-bottom: -12%;
    z-index: -50;
}

.home-header-text {
    position: absolute;
    color: white;
    -webkit-text-stroke: 2px black;
    display: flex;
    justify-content: flex-start;
    height: 280px;
    align-items: center;
    font-size: 2rem;
}

*/
.home-container {
    position: relative;
}

.home-header {
    display: flex;
    position: relative;
}

.picture-header {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    /*clip-path: polygon(0 15%, 100% 15%, 100% 100%, 0 100%);*/
    z-index: -5;
    margin-top: -90px;
}

.logo-header {
    position: absolute;
    height: 30px;
    top: 50px;
    left: 900px;
}

.home-header-overlay-logo {
    position: absolute;
    top: 40px;
    left: 50px;
    color: white;
    width: 500px;
}

.home-header-overlay-logo h1 {
    font-family: 'Rockwell', 'Poppins', sans-serif;
    font-size: 2.65rem;
    color: white;
    font-weight: 700;
    text-shadow: 0 0 12px rgba(128, 128, 128, 0.4);
}

.home-header-overlay-title {
    position: absolute;
    top: 300px;
    left: 50px;
    color: white;
    width: 510px;
    border-radius: 10px;
}  

.home-header-overlay-title h1 {
    text-shadow: 0 0 5px rgba(128, 128, 128, 0.4);
}

.header-titles {
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 15%;
    padding-left: 10%;
}

.home-container-text {
    margin: 100px 120px;
}

.home-container-text h4 {
    font-size: larger;
}

.home-container-sub-text {
    margin: 0 120px;
    text-align: center;
    padding-bottom: 50px;
}

.home-container-note {
    margin: 0 120px;
    text-align: center;
    margin-bottom: 4px;
    padding-bottom: 20px;
}

.wrap-alter {
    background-color: #F5F6F8;
    padding-top: 20px;
    margin-bottom: -20px;
}

.dog {
    width: 120px;
    margin-bottom: -10px;
    margin-top: -10px;
}

.city {
    width: 100px;
    position: absolute;
    top: -15px;
}

.forest {
    width: 100px;
    position: absolute;
    top: -15px;
}

.sun {
    width: 100px;
    margin-bottom: -10px;
    margin-top: -10px;
}

.moon {
    width: 90px;
    margin-bottom: -10px;
    margin-top: -10px;
}

.image-gradient {
    background: rgb(255,188,3);
    background: linear-gradient(45deg, rgba(255,188,3,1) 14%, rgba(255,249,67,1) 100%);
    /*filter: blur(20px);*/
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
}

.image-gradient-container {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    height: 100px;
}

.text-columns-3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /*grid-template-columns: 1fr 1fr 1fr;*/
    gap: 30px;
    margin: 30px 120px;
    justify-content: center;
}

.max-width-gradient {
    max-width: 307px;
}

.text-columns-3 h3 {
    text-align: center;
    margin-top: 20px;
}

.text-columns-3 p {
    text-align: center;
}

.home-hero-image {
    width: 300px;
    margin-top: -100px;
}

.choose-me {
    display: flex;
    justify-content: center;
}

.hero-image-2 {
    width: 100vw;
    height: 70vh;
    object-fit: cover;
    object-position: 0 -250px;
}

.home-container-text li {
    font-family: 'Prompt', 'Roboto', sans-serif;
    font-weight: 200;
    font-size: 1rem;
}

.home-box-button {
	box-shadow: 3px 4px 0px 0px #e0ad21;
	background:linear-gradient(to bottom, #ffbc03 5%, #ffbc03 100%);
	background-color:#ffbc03;
	border-radius:5px;
	border:1px solid #d1b873;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:17px;
	font-weight:bold;
	padding:12px 44px;
	text-decoration:none;
	text-shadow:0px 1px 0px #d1ac47;
    margin-bottom: 20px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.home-box-button:hover {
	background:linear-gradient(to bottom, #ffbc03 5%, #ffbc03 100%);
	background-color:#ffbc03;
}
.home-box-button:active {
	position:relative;
	bottom:-2px;
}

.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.padding {
    padding: 50px 0;
    margin-top: -50px;
}

.instagram-posts-wrapper {
    margin-top: 50px;
    width: 100%;
}

.instagram-posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.instagram-post {
    position: relative;
}

.instagram-post img {
    width: 250px;
    height: 250px;
    object-fit: cover;
}

.instagram-post-hover {
    background: rgba(0, 0, 0, 0);
    width: 250px;
    height: 250px;
    position: absolute;
    z-index: 2;
    transition: 0.5s;
}

.instagram-post-hover:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.4);
}

.warning-message {
    font-weight: 300;
    color: #fa2711;
}

@media only screen and (max-width: 984px) {
    .text-columns-3 {
        display: flex;
        flex-direction: column;
        gap: 50px;
        margin: 20px 20px;
    }

    .choose-me {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .choose-me h2 {
        margin-bottom: 20px;
    }

    .choose-me-one {
        margin-bottom: -30px;
    }

    .home-container-sub-text {
        margin: 0 20px;
    }

    .home-container-note {
        margin: 0 20px;
        padding-bottom: 0;
    }

    .home-container-text {
        margin: 60px 20px;
    }

    .home-container-text h4 {
        font-weight: 300;
        font-family: 'Prompt', sans-serif;
        text-align: left;
    }

    .d-flex-center {
        display: flex;
        justify-content: center;
    }

    .home-hero-image {
        margin-top: 20px;
    }

    .wrap-alter {
        padding: 40px 20px;
    }

    .wrap-alter p {
        text-align: center;
    }

    .home-box-button {
        margin-bottom: 0;
    }

    .home-header-overlay-title {
        margin: 0 20px;
        left: 0;
        width: 85vw;
        text-align: center;
        top: 100px;
    }

    .home-header-overlay-title h1 {
        font-size: 1.6rem;
    }

    .hero-image-2 {
        width: 100vw;
        height: unset;
        object-fit: unset;
        object-position: unset;
        /*object-position: 70% 70%;*/
    }

    .picture-header {
        object-position: 70% 0;
    }

    .instagram-post img {
        width: 150px;
        height: 150px;
        object-fit: cover;
    }

    .instagram-post-hover {
        background: rgba(0, 0, 0, 0);
        width: 150px;
        height: 150px;
        position: absolute;
        z-index: 2;
        transition: 0.5s;
    }

    .home-container-text .d-flex-center h3 {
        text-align: center;
    }

    .instagram-posts-wrapper {
        margin-top: 20px;
    }

    .home-header-overlay-logo {
        width: 100vw;
        display: flex;
        justify-content: center;
        left: 0;
    }

}