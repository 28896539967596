.question .q {
    font-weight: 500;
}

.q {
    position: relative;
    display: inline-block;
}

.accordion-button:not(.collapsed) {
    background-color: #F2F3F4  !important;
    color: black !important;
}