.privacy-policy-wrapper {
    margin: 0 120px;
}

.privacy-policy-wrapper li {
    font-family: 'Prompt', sans-serif;
    font-weight: 200;
}

@media only screen and (max-width: 984px) {
    .privacy-policy-wrapper {
        margin: 0 20px;
    }
}