@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap');

html {
    overflow-x: hidden;
}

body {
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
}

ul {
    padding: 0;
}

a, a:hover {
    text-decoration: none;
    color: black;
}

.logo {
    width: 70px;
    filter: saturate(1.2);
}

.d-none {
    display: none;
}

.title h1 {
    font-family: 'Prompt', sans-serif;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
}

h1, h2, h3 {
    font-family: 'Prompt', sans-serif;
}

p {
    font-family: 'Prompt', 'Roboto', sans-serif;
    font-weight: 200;
    font-size: 1rem;
}

.text-list {
    padding-left: 50px;
}

.text-list li {
    font-family: 'Prompt', 'Roboto', sans-serif;
    font-weight: 200;
}

.banner-header img {
    width: 100vw;
    object-fit: cover;
    height: 280px;
    object-position: 0% 45.5%;
}

.banner-header-text {
    position: absolute;
    color: black;
    /*-webkit-text-stroke: 2px black; */
    display: flex;
    justify-content: flex-start;
    height: 280px;
    align-items: center;
    font-size: 2rem;
}

.banner-header-text h1 {
    padding: 10px 10px 10px 200px;
    background-color: rgba(255, 255, 255, 0.5);
}

.img-medium {
    width: 550px;
}

li::marker {
    font-size: 0.8rem;
}

a {
    text-decoration: none;
}

p a {
    color: #0D6EFD;
}

.css-1nbakwd {
    background-color: #ffbc03 !important;
}