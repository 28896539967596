.about-header-image {
    height: 80vh;
    width: 30vw;
    object-fit: cover;
}

.about-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

.about-header-reverse {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.about-header-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0;
}

.about-header-title h1 {
    margin: 0;
    text-align: center;
}

.about-header-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 50px;
    margin-right: 50px;
}

.about-header-text-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 50px 0 50px;
}

.about-video {
    width: 30vw;
}

.about-header-image-2 {
    width: 100vw;
    height: 30vh;
    object-fit: cover;
    object-position: 0 27.5%;
}

.about-header-text-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 50px 100px;
}

.relative {
    position: relative;
}

.about-header-image-hor {
    height: 300px;
    width: 500px;
    object-fit: cover;
}

.about-header-image-ver {
    height: 500px;
    width: 300px;
    object-fit: cover;
}

.about-header-image-hor-mini {
    height: 150px;
    width: 200px;
    object-fit: cover;
}

.picture-background {
    position: absolute;
    height: 400px;
    width: 400px;
    background-color: #FFD447;
    top: 20%;
    z-index: -1;
    border-radius: 50%;
}

.d-flex-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.image-line-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 100px;
    margin: 25px 100px;
}

.image-line-center-gap-min {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 25px;
    margin: 25px 100px;
}

.border-radius-10-tl {
    border-top-left-radius: 10%;
}

.border-radius-10-tr {
    border-top-right-radius: 10%;
}

.border-radius-10-bl {
    border-bottom-left-radius: 10%;
}

.border-radius-10-br {
    border-bottom-right-radius: 10%;
}

.square-image {
    width: 600px;
}

/***** HISTORY PAGE *****/

.present-picture {
   object-fit:contain;
   width: 400px;
   padding-left: 10px;
}

.p-image {
    display: flex;
    align-items: flex-start;
}

/***** EXPERIENCE *****/

.experience-image {
    object-fit:contain;
    width: 400px;
}

.history-image-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 50px 0 50px;
    gap: 50px;
    width: 1180px;
}

.history-image-right {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 0 50px 0 50px;
    gap: 50px;
    width: 1180px;
}

.history-image-wrapper {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.history-image-wrapper li {
    font-size: 1rem !important;
}

.history-smaller-image {
    height: 600px;
}

/***** MEDIA QUERIES *****/

@media only screen and (max-width: 1440px) {

    .square-image {
        width: 550px;
    }
}

@media only screen and (max-width: 984px) {
    .about-header {
        flex-direction: column;
        align-items: center;
    }

    .about-header-reverse {
        flex-direction: column;
        align-items: center;
    }

    .history-image-left {
        display: flex;
        flex-direction: column;
        padding: unset;
        width: unset;;
    }

    .responsive-width-no-image {
        width: 400px;
    }

    .history-image-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: unset;
        width: unset;
    }

    .present-picture {
        padding: 0;
    }

    .reverse {
        flex-direction: column-reverse;
    }

    .history-image-wrapper {
        margin-bottom: 36px;
    }

    .history-image-wrapper h2 {
        margin-bottom: 0.6rem;
    }

    .mb-0-responsive {
        margin-bottom: 0;
    }

    .about-header-title-responsive-width h1 {
        width: 200px;
    }

}

@media only screen and (min-width: 1000px) {
    .responsive-margins {
        width: 1300px;
    }

}