.promo-item-container {
    height: 30px;
    /*background-color: #850A0C;*/
    background-color: #FFBC03;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    position: relative;
    padding: 0 10px;
}

.promo-item-container a p {
    font-weight: 400;
    font-size: 0.98rem;
    padding: 0;
    margin: 0;
    color: white;
    padding: 0 !important;
}

.promo-item-close-button {
    position: absolute;
    right: 25px;
    cursor: pointer;
}

@media only screen and (max-width: 984px) {
    .promo-item-container {
        height: 60px;
        justify-content: flex-start;
    }

    .promo-item-container a p {
        font-size: 1rem;
        margin-top: -1px;
        width: 300px;
    }

    .promo-item-close-button {
        right: 5%;
        font-size: 25px;
    }

}