.priceBox {
    display: flex;
    padding: 30px 40px;
    gap: 50px;
    border-radius: 10px;
    border: 0.5px solid #E2E8F0;
}

.priceBox ul {
    padding: 0;
    list-style: none;
    width: 45vw;
}

.main-button-container {
    width: 100%;
}

@media only screen and (max-width: 984px) {
    .priceBox {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 17px;
        margin: 10px;
        padding: 20px 30px;
    }

    .priceBox ul {
        width: 100%;
        margin-bottom: 17px;
    }

    .main-button-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}