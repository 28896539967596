.feedback-masonry {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    width: 80%;

}

.clients-feedback {
    border: 1px solid whitesmoke;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    width: 1000px;
    gap: 10px;
}

.clients-feedback-text {
    padding: 0px 20px;
    width: 50vw;
    height: 188px;
    overflow: hidden;
}

.clients-feedback-text-expanded {
    padding: 0px 20px;
    width: 50vw;
    height: 100%;
    overflow: hidden;
}

.clients-image-container {
    width: 200px;
    height: 200px;
    margin-bottom: 10px;
}

.clients img {
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.clients-feedback-author {
    font-weight: 400;
    text-align: left;
}

.see-more {
    display: flex;
    align-items: flex-end;
    height: 100%;
    width: 100px;
}

.see-more p {
    font-weight: 400;
    cursor: pointer;
}

.see-more-small {
    display: none;
}

.display-mobile {
    display: none;
}

@media only screen and (max-width: 984px) {

    .display-mobile {
        display: flex;
    }

    .clients-feedback {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .see-more {
        height: 10px;
        margin-top: 40px;
    }

    .clients-feedback-text {
        width: 100%;
        height: 100px;
    }

    .clients-feedback-text-expanded {
        width: 100%;
    }

    .see-more-small {
        display: flex;
        align-items: flex-end;
        width: 100px;
    }

    .see-more-small p {
        font-weight: 400;
        cursor: pointer;
    }

    .clients-image-container {
        width: 300px;
        height: 300px;
    }

    .clients-image-container img {
        width: 300px;
        height: 300px;
    }

}