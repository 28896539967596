.price-box-container {
    width:100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    position: relative;
}

.price-note {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.price-note p {
    font-size: 1rem;
    font-family: 'Prompt', sans-serif !important;
    color: #212529;
    margin: 0;
    width: 50%;
    text-align: center;
}

.price-box-highlight {
    margin-bottom: 10px;
}

.price-box-highlight p {
    font-weight: 400;
    padding: 0;
    margin: 0;
    font-size: 1.2rem;
}

.price-box-dehighlight {
    font-weight: 300 !important;
    font-size: 0.9rem !important;
}

.price-box-550 {
    width: 350px;
    background-color: #F5F6FA;
    padding: 20px;
    position: relative;
    height: 550px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 2px 3px 8px;
}

.price-box-600 {
    width: 350px;
    background-color: #F5F6FA;
    padding: 20px;
    position: relative;
    height: 600px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 2px 3px 8px;
}

.price-box-550 ul, .price-box-600 ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.price-box-550 li, .price-box-600 li {
    font-family: 'Prompt', 'Roboto', sans-serif !important;
    font-size: 1rem;
    font-weight: 200;
}

.price-box-550 ul li:before, .price-box-600 ul li:before {
    content: '✓ ';
  }

.price-box-iva {
    font-size: 1rem;
    font-weight: 300;
}

.pack-1 {
    font-size: medium;
    font-weight: 500;
    color: white;
    background-color: rgb(118,15,18);
    text-align: center;
    margin-top: 10px;
    text-transform: uppercase;
}

.pack-2 {
    font-size: medium;
    font-weight: 500;
    color: white;
    background-color: rgb(118,15,18);
    text-align: center;
    margin-top: 10px;
    text-transform: uppercase;
}

.pack-3 {
    font-size: medium;
    font-weight: 500;
    color: white;
    background-color: rgb(118,15,18);
    text-align: center;
    margin-top: 10px;
    text-transform: uppercase;
}

.price-box-subcontainer {
    width: 1300px;
}

@media only screen and (max-width: 984px) {
    .price-note {
        width: 100vw;
       
    }

    .price-note p {
        text-align: center;
        width: 85%;
    }

    .price-box-container {
        width: 100vw;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .price-box-subcontainer {
        width: unset;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}