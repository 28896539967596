footer {
    background-color: #F5F6FA;
    height: 300px;
}

.footer-main {
    height: 220px;
    background-color: #F5F6FA;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}

.footer-main p {
    padding: 0;
    margin: 0;
}

.footer-main img {
    width: 175px;
    position: relative;
}

.footer-contact {
    width: 400px;
    position: relative;
    padding-right: 250px;
}

.footer-contact p {
    font-size: small;
}

.footer-contact-title hr {
    margin-top: 10px;
    margin-bottom: 10px;
}

.footer-contact-title h6 {
    margin: 0;
    padding: 0;
}

.footer-button {
    height: 38px;
	box-shadow: 3px 4px 0px 0px #e0ad21;
	background:linear-gradient(to bottom, #ffbc03 5%, #ffbc03 100%);
	background-color:#ffbc03;
	border-radius:5px;
	border:1px solid #d1b873;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:14px;
	font-weight:bold;
	padding: 6px 22px;
	text-decoration:none;
	text-shadow:0px 1px 0px #d1ac47;
    bottom: 0;
    left: 25%;
    margin-top: 70px;
}
.footer-button:hover {
	background:linear-gradient(to bottom, #ffbc03 5%, #ffbc03 100%);
	background-color:#ffbc03;
}
.footer-button:active {
	margin-top: 62px;
}

.footer-CTA {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
}

.instagram-logo svg {
    position: relative;
    left:0;
    margin-left: -1px;
}

.copyright-terms {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.copyright-terms p {
    font-size: 0.8rem;
    margin: 3px;
}

.footer-contact-details {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.footer-contact-details p {
    margin: 0;
    padding: 0;
}

.footer-contact-details-inner {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
}

.gap-20 {
    gap:13px;
}


@media only screen and (max-width: 984px) {

    footer {
        background-color: #F5F6FA;
        height: 450px;
    }

    .footer-main {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 400px;
        margin-top: 0;
        gap: 15px;
    }

    .footer-contact {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer-contact-details {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-left: 40px;
    }

    .footer-contact-details-inner {
        display: flex;
        flex-direction: column;
    }

    .footer-contact-details p {
        font-size: 0.9rem;
    }

    .gap-20 {
        gap: 16px;
    }

    .footer-contact-title {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .footer-CTA .footer-button {
        height: 38px;
        margin-top: 50px;
    }

    .footer-CTA img {
        width: 150px;
    }

    .copyright-terms {
        margin-top: -20px;
    }
}