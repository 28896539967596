.contact-content {
    margin: 0 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 70px;
}

.contact-message-area {
    width: 400px;
}

.contact-message-area img {
    width: 400px;
}

.contact-form-area {
    width: 40vw;
    position: relative;
    margin-bottom: 80px;
}

.contact-form textarea {
    width: 100%;
    padding: 11px 20px;
    font-size: 15px;
    border: 1px solid #707070;
    border-radius: 0;
    cursor: pointer;
    transition: color .3s ease-out, background-color .3s ease-out, border-color .3s ease-out;
    font-family: 'Prompt', 'Roboto', sans-serif;
    font-weight: 300;
}

textarea:focus {
    border: 1.5px solid #707070;
    outline: none;
}

.form-inputs {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.form-inputs input {
    border: 1px solid #707070;
    border-radius: 0;
    padding: 11px 20px;
    width: 49%;
    font-size: 15px;
    font-family: 'Prompt', 'Roboto', sans-serif;
    font-weight: 300;
}

.form-inputs input:focus {
    border: 1.5px solid #707070;
    outline: none;
}

.input-group-contact {
    position: relative;
    width: 49%;
}

.input-group-contact span {
    font-size: 0.8rem;
    color: red;
    position: absolute;
    left: 5px;
}

.input-group-contact input {
    width: 100%;
}

.input-group-contact-message {
    width: 100%;
    position: relative;
}

.input-group-contact-message span {
    font-size: 0.8rem;
    color: red;
    position: absolute;
    left: 5px;
}

.send {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    cursor: pointer;
    transition: 2s;
    border-radius:5px;
	padding:6px 22px;
    background-color: #dee0e0;
    color: black;
    position: absolute;
    right: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.send p {
    margin: 0;
    padding: 0;
    font-size: medium;
    font-weight: 300;
}

.send p:hover {
    font-weight: 300;
}

.form-sent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.green-check-form {
    color: green;
    font-size: 4rem;
}

.privacy-inputs {
    display: flex;
    align-items: center;
    gap: 5px;
}

.privacy-inputs p {
    padding: 0;
    margin: 0;
}

.privacy-inputs span {
    font-size: 0.8rem;
    color: red;
}

@media only screen and (max-width: 984px) {

    .form-inputs input {
        width: 100%;
    }
    
    .contact-content {
        flex-direction: column;
        align-items: center;
    }

    .contact-form-area {
        width: 90vw;
    }

    .contact-form {
        margin-top: 25px;
    }

    .contact-content {
        gap: 50px;
    }

    .input-group-contact {
        width: 100%;
    }

    .send {
        margin-top: 10px;
    }
}